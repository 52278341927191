const roseRed = '#C6015C'
const purple = '#5C0099'
const pink = '#A18288'
const blush = '#EDE8EA'

export {
    roseRed,
    purple,
    pink,
    blush
}